var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: " c-AppHeaderComponent navbar bg-dark text-light"
  }, [_c('div', {
    staticClass: "container d-flex justify-content-between"
  }, [_vm.screenWidth > 600 || !_vm.list._id ? _c('div', {
    staticClass: "logo font-element"
  }, [_c('a', {
    staticClass: "logo__link link-light text-decoration-none",
    attrs: {
      "href": "https://ucze.net"
    }
  }, [_vm._v("ucze.net")])]) : _vm._e(), _vm.list._id && _vm.screenWidth > 600 ? _c('div', [_vm._v(" " + _vm._s(_vm.list.name) + " "), _c('span', {
    staticClass: "p-1 fw-bold"
  })]) : _vm._e(), _c('div', [_vm.list._id ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.player._id && _vm.screenWidth > 600 ? _c('span', {
    staticClass: "me-4"
  }, [_vm._v(" " + _vm._s(_vm.player.name) + " ")]) : _vm._e(), _vm.list._id ? _c('button', {
    staticClass: "btn btn-outline-danger ms-2",
    on: {
      "click": _vm.restartApp
    }
  }, [_vm._v("Zamknij")]) : _vm._e()]) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }