var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c-AppLoaderComponent position-fixed w-100 h-100 d-flex top-0 flex-column align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "spinner-grow text-primary",
    attrs: {
      "role": "status"
    }
  }), _c('div', {
    staticClass: "spinner-grow text-secondary",
    attrs: {
      "role": "status"
    }
  }), _c('div', {
    staticClass: "spinner-grow text-success",
    attrs: {
      "role": "status"
    }
  }), _c('div', {
    staticClass: "spinner-grow text-danger",
    attrs: {
      "role": "status"
    }
  }), _c('div', {
    staticClass: "spinner-grow text-warning",
    attrs: {
      "role": "status"
    }
  })])])]);
}]

export { render, staticRenderFns }