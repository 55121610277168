var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shown ? _c('div', {
    staticClass: "c-PWAPrompt w-100"
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": "/img/icons/android-chrome-512x512.png",
      "alt": ""
    },
    on: {
      "click": _vm.installPWA
    }
  }), _c('div', {
    staticClass: "text",
    on: {
      "click": _vm.installPWA
    }
  }, [_vm._v(" Pobierz aplikacje ")]), _c('div', {
    staticClass: "close",
    on: {
      "click": _vm.dismissPrompt
    }
  }, [_vm._v(" x ")])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }