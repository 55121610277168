var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "c-AppFooterComponent navbar bg-dark text-light"
  }, [_c('div', {
    staticClass: "container d-flex flex-column justify-content-center"
  }, [_c('a', {
    staticClass: "link link-light text-decoration-none font-element fs-2",
    attrs: {
      "href": "https://ucze.net"
    }
  }, [_vm._v("ucze.net")]), _c('a', {
    staticClass: "link-freepik text-white text-decoration-none",
    attrs: {
      "href": "https://www.freepik.com/free-vector/watercolor-back-school-background-with-white-space_9001487.htm#query=watercolor-back-school-background&position=0&from_view=search&track=sph"
    }
  }, [_vm._v(" Image by pikisuperstar on Freepik ")])])]);
}]

export { render, staticRenderFns }