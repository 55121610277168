var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-ResultView my-auto"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "summary row"
  }, [_c('div', {
    staticClass: "col-12 text-center"
  }, [_c('h1', {
    staticClass: "summary__text"
  }, [_vm._v(" Błędy: " + _vm._s(_vm.wrongAnswers.length) + " ")]), !_vm.wrongAnswers.length ? _c('h2', {
    staticClass: "summary__congrats"
  }, [_vm._v(" Gratulacje ! ")]) : _vm._e()])]), _c('div', {
    staticClass: "buttons row justify-content-center gy-2"
  }, [_c('div', {
    staticClass: "col-12 col-md-6 col-lg-4"
  }, [_c('button', {
    staticClass: "buttons__button btn btn-primary w-100",
    on: {
      "click": _vm.startAgain
    }
  }, [_vm._v(" Rozwiąż jeszcze raz ")])]), _c('div', {
    staticClass: "col-12 col-md-6 col-lg-4"
  }, [_c('button', {
    staticClass: "buttons__button btn btn-danger w-100",
    on: {
      "click": _vm.restartApp
    }
  }, [_vm._v(" Koniec ")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }