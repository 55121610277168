<template>
  <nav class="c-AppFooterComponent navbar bg-dark text-light">
    <div class="container d-flex flex-column justify-content-center">
      <a class="link link-light text-decoration-none font-element fs-2" href="https://ucze.net">ucze.net</a>

      <a class="link-freepik text-white text-decoration-none"
         href="https://www.freepik.com/free-vector/watercolor-back-school-background-with-white-space_9001487.htm#query=watercolor-back-school-background&position=0&from_view=search&track=sph"
      >
        Image by pikisuperstar on Freepik
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'AppFooterComponent',
}
</script>

<style lang="scss" scoped>
  .c-AppFooterComponent{

    .link-freepik{
      font-size: 0.8em;
    }
  }
</style>