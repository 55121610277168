var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column min-vh-100",
    attrs: {
      "id": "app"
    }
  }, [_vm.appLoader ? _c('AppLoaderComponent') : _vm._e(), _c('AppHeaderComponent', {
    attrs: {
      "shortCode": _vm.shortCode,
      "list": _vm.list,
      "player": _vm.player,
      "clearPlayerSession": _vm.clearPlayerSession,
      "setAppLoader": _vm.setAppLoader
    }
  }), _c('router-view'), _c('AppFooterComponent')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }