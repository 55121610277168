var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-ExerciseView my-auto",
    class: {
      'v-ExerciseView--no-image': !_vm.exercise.imageObject
    }
  }, [_vm.exercise.content ? _c('div', {
    staticClass: "container text-center"
  }, [_vm.exercise.imageObject ? _c('div', {
    staticClass: "image-container row"
  }, [_c('div', {
    staticClass: "col-auto position-relative justify-content-center mx-auto"
  }, [_vm.exercise.imageObject.srcType === 'url' ? _c('img', {
    staticClass: "image-container__img",
    class: {
      'image-container__img--magnified': _vm.magnification
    },
    attrs: {
      "src": _vm.exercise.imageObject.url,
      "alt": ""
    }
  }) : _vm._e(), _vm.exercise.imageObject.srcType === 'buffer' ? _c('img', {
    staticClass: "image-container__img",
    class: {
      'image-container__img--magnified': _vm.magnification
    },
    attrs: {
      "src": "data:".concat(_vm.exercise.imageObject.mimetype, ";base64,").concat(_vm.exercise.imageObject.src),
      "alt": _vm.exercise.imageObject.originalname
    }
  }) : _vm._e(), !_vm.magnification ? _c('i', {
    staticClass: "image-container__icon bi bi-arrows-fullscreen",
    on: {
      "click": function click($event) {
        _vm.magnification = true;
      }
    }
  }) : _vm._e(), _vm.magnification ? _c('i', {
    staticClass: "image-container__icon bi bi-box-arrow-in-down-left",
    on: {
      "click": function click($event) {
        _vm.magnification = false;
      }
    }
  }) : _vm._e()])]) : _vm._e(), _vm.isYoutubeVideoAttached ? _c('YoutubeComponent', {
    key: _vm.exercise.content.youtubeVideo.ytId,
    attrs: {
      "video": _vm.exercise.content.youtubeVideo
    }
  }) : _vm._e(), _vm.exercise.content.content ? _c('div', {
    staticClass: "content-container row",
    class: ["font-".concat(_vm.exercise.content.contentFont)]
  }, [_c('div', {
    staticClass: "col"
  }, [_c('p', {
    staticClass: "text-content",
    class: {
      'text-content--long': _vm.exercise.content.content.length > 100,
      'text-content--very-long': _vm.exercise.content.content.length > 500,
      'text-content--no-image': !_vm.exercise.imageObject
    }
  }, [_vm._v(_vm._s(_vm.exercise.content.content))])])]) : _vm._e(), _c('div', {
    staticClass: "answers-container row justify-content-center",
    class: ["font-".concat(_vm.exercise.content.answersFont)]
  }, [_c('div', {
    staticClass: "col-auto"
  }, _vm._l(_vm.exercise.content.answerOptions, function (option) {
    return _c('button', {
      key: option._id,
      staticClass: "answer-button btn btn-primary white mb-3 w-100",
      class: {
        'btn-danger': _vm.wrongAnswer && option._id === _vm.userAnswerOption._id,
        'btn-success': !_vm.wrongAnswer && option._id === _vm.userAnswerOption._id
      },
      attrs: {
        "disabled": _vm.answerLoader || _vm.userAnswerOption._id && _vm.userAnswerOption.isCorrect
      },
      on: {
        "click": function click($event) {
          return _vm.handleAnswer(option);
        }
      }
    }, [_vm._v(" " + _vm._s(option.text) + " ")]);
  }), 0)])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }